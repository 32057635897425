import React, {useState, useEffect} from 'react';
import {useLocation} from "react-router-dom";
import {signOut, getAuth} from "firebase/auth";
import {useAuthState} from '../Firebase';

import {Navbar, Nav, Container, Button} from 'react-bootstrap';


const Navigation = (props) => {

  const authState = useAuthState();
  const auth = getAuth();
  
  const location = useLocation();
  const match = location.pathname.match(/\/edges\/([^/]+)\/?/); // Regex to extract :eid
  const eid = match ? match[1] : null;

  return (
    <Navbar className="navbar-custom" bg="light" variant="light">
      <Container>
        <Navbar.Brand href="/">Smart Charge Controller</Navbar.Brand>
        <Nav className="me-auto">
          {eid && 
            <>
              <Nav.Link href={`/edges/${eid}/statistics`}>Statistics</Nav.Link>
              <Nav.Link href={`/edges/${eid}/configuration`}>Configuration</Nav.Link>
            </>
          }
        </Nav>
        {authState.user !== undefined && authState.user !== null && (
          <Nav>
            <Nav.Link href="#" onClick={() => {
              signOut(auth)
            }}>Logout</Nav.Link>
          </Nav>
        )}
      </Container>
    </Navbar>
  );
}

export default Navigation;