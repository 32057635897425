import React, {useState, useEffect, useRef} from 'react';
import {useParams} from 'react-router-dom';

import moment from 'moment';

import {Row, Col, Dropdown, Button, Accordion} from 'react-bootstrap';
import {ClockHistory, ArrowClockwise} from 'react-bootstrap-icons';

import {getDoc, doc} from 'firebase/firestore';
import {db} from '../Firebase';

import {
  smartPiCharts,
  modbusRTUMeterChart,
  modbusRTUMeterZEVChart,
  modbusTCPMeterChart,
  rpiGpio,
  easeeCircuitChart,
  zaptecInstallationChart,
  alfenSCNChart,
  alfenSocketChart,
  alpitronicHyperchargerChart,
  dsmr5Chart, shellyProMeterChart
} from '../_helpers/chartsDefinitions';

import Lchart from './lchart';
import CustomRangeStat from "./customRange";

const Statistics = (props) => {
  const [chartsConfig, setChartsConfig] = useState();
  const {eid} = useParams();

  const [range, setRange] = useState() // to display the range only
  const [start, setStart] = useState();
  const [end, setEnd] = useState();

  const findEVs = (circuit) => {
    let res = [];
    circuit?.evChargers?.map(evCharger => {
      if (evCharger.easeeCircuit) {
        res.push({
          name: `Easee Circuit, SiteId: ${evCharger.easeeCircuit.siteId}, CircuitId: ${evCharger.easeeCircuit.circuitId}`,
          charts: easeeCircuitChart(evCharger.easeeCircuit.siteId, evCharger.easeeCircuit.circuitId)
        })
      } else if (evCharger.zaptecInstallation) {
        res.push({
          name: `Zaptec Installation, installationId: ${evCharger.zaptecInstallation.installationId}`,
          charts: zaptecInstallationChart(evCharger.zaptecInstallation.installationId)
        })
      } else if (evCharger.alfenSCN) {
        res.push({
          name: `Alfen SCN, IP: ${evCharger.alfenSCN.ip}`,
          charts: alfenSCNChart(evCharger.alfenSCN.ip)
        })
      } else if (evCharger.alfenSocket) {
        res.push({
          name: `Alfen Socket, IP: ${evCharger.alfenSocket.ip}, SocketId: ${evCharger.alfenSocket.socketId}`,
          charts: alfenSocketChart(evCharger.alfenSocket.ip, evCharger.alfenSocket.socketId)
        })
      } else if (evCharger.alpitronicHypercharger) {
        res.push({
          name: `Alpitronic HYC, IP: ${evCharger.alpitronicHypercharger.ip}`,
          charts: alpitronicHyperchargerChart(evCharger.alpitronicHypercharger.ip)
        })
      }
    });
    if (circuit.circuits) {
      circuit.circuits.forEach(c => {
        res = [...res, ...findEVs(c)];
      })
    }
    return res;
  }

  const init = async () => {
    const edgeSnap = await getDoc(doc(db, "edges", eid));
    if (edgeSnap.exists()) {
      const snapData = edgeSnap.data();
      if (snapData.lifecycle.status !== "activated-legacy") {
        // core
        const activeConfig = await getDoc(doc(db, "edges", eid, "activeConfig", "v1"));
        if (activeConfig.exists()) {
          let chartsConf = {
            influxAPIUrl: "https://influxapi.smartchargecontroller.ch/v1?bucket=sccmetrics",
            nrOfDataPoints: 360,
            groups: []
          };
          let conf = JSON.parse(activeConfig.data().value);
          if (conf.devices.SmartPi) {
            chartsConf.groups.push({
              name: conf.devices.SmartPi.name,
              charts: smartPiCharts,
            })
          }

          if (conf.devices.ModbusRTUMeter) {
            conf.devices.ModbusRTUMeter.forEach(m => {
              if (m.deviceType === "PRO380-Mod-ZEV") {
                chartsConf.groups.push({
                  name: m.name,
                  charts: modbusRTUMeterZEVChart(m.name)
                })
              } else {
                chartsConf.groups.push({
                  name: m.name,
                  charts: modbusRTUMeterChart(m.name)
                })
              }
            })
          }

          if (conf.devices.ModbusTCPMeter) {
            console.log("This modbus tcp")
            conf.devices.ModbusTCPMeter.forEach(m => {
              chartsConf.groups.push({
                name: m.name,
                charts: modbusTCPMeterChart(m.name)
              })
            })
          }

          if (conf.devices.DSMR5) {
            conf.devices.DSMR5.forEach(m => {
              chartsConf.groups.push({
                name: m.name,
                charts: dsmr5Chart(m.name)
              })
            })
          }

          if (conf.devices.RpiGPIO) {
            conf.devices.RpiGPIO.forEach(r => {
              chartsConf.groups.push({
                name: r.name,
                charts: rpiGpio
              })
            })
          }

          if (conf.devices.ShellyPro3EM) {
            console.log("This shelly pro")
            conf.devices.ShellyPro3EM.forEach(m => {

              chartsConf.groups.push({
                name: m.name,
                charts: shellyProMeterChart(m.name)
              })

            });
          }

          conf.circuits.forEach(circuit => chartsConf.groups.push(...findEVs(circuit)));
          setChartsConfig(chartsConf)
        }
      } else {
        // legacy
        const cConfig = snapData.chartsConfig || 'default';
        getDoc(doc(db, "chartsConfig", cConfig)).then(snap => {
          if (snap.exists()) {
            setChartsConfig(snap.data());
          }
        })
      }
    }
  }

  const updateRelativeRange = async (relativeValue) => {
    let date = new Date();
    let hours = 24;
    if (relativeValue === "1h") {
      hours = 1;
    } else if (relativeValue === "7d") {
      hours = 7 * 24;
    } else if (relativeValue === "30d") {
      hours = 30 * 24;
    }
    setEnd(date.toISOString());

    date.setTime(date.getTime() - (hours * 60 * 60 * 1000));
    setStart(date.toISOString());

    // update range to display the relative value
    setRange(relativeValue);

  }

  const updateAbsoluteRange = async (start, end) => {
    setStart(start);
    setEnd(end);

    setRange(`${moment(start).format('DD.MM.YYYY HH:mm')} - ${moment(end).format('DD.MM.YYYY HH:mm')}`);
  }

  useEffect(() => {
    (async () => {
      await init();
      await updateRelativeRange("1h");
    })();
  }, [])

  const showCustomRangeModal = useRef(null);


  return (
    <>
      <Row className="mb-2">
        <Col><h5>Statistics</h5></Col>
        <Col md="auto">
          <Button variant='outline-secondary' onClick={() => updateRelativeRange(range)}><ArrowClockwise/></Button>
        </Col>
        <Col md="auto">
          <Dropdown className="float-end">
            <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic">
              <ClockHistory/><span> {range} </span>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={() => updateRelativeRange("1h")}>1h</Dropdown.Item>
              <Dropdown.Item onClick={() => updateRelativeRange("1d")}>1d</Dropdown.Item>
              <Dropdown.Item onClick={() => updateRelativeRange("7d")}>7d</Dropdown.Item>
              <Dropdown.Item onClick={() => updateRelativeRange("30d")}>30d</Dropdown.Item>
              <Dropdown.Item onClick={function () {
                showCustomRangeModal.current();
              }}>Benutzerdefiniert</Dropdown.Item>
            </Dropdown.Menu>
            <CustomRangeStat updateAbsoluteRange={updateAbsoluteRange}
                             onShow={(showFunc) => showCustomRangeModal.current = showFunc}/>
          </Dropdown>
        </Col>
      </Row>
      <Accordion className="shadow" defaultActiveKey={['SmartPi']} flush alwaysOpen>
        {chartsConfig?.groups?.map(group =>
          <Accordion.Item className="text-center" key={group.name} eventKey={group.name}>
            <Accordion.Header>{group.name}</Accordion.Header>
            <Accordion.Body>
              <Row xs={1} md={2}>
                {group.charts?.map(chart =>
                  <Col key={chart.name}>
                    <Lchart
                      eid={eid}
                      chart={chart}
                      nrOfDataPoints={chartsConfig.nrOfDataPoints}
                      start={start}
                      end={end}
                      updateAbsoluteRange={updateAbsoluteRange} // for zoom function
                      influxAPIUrl={chartsConfig.influxAPIUrl}
                    />
                  </Col>
                )}
              </Row>
            </Accordion.Body>
          </Accordion.Item>
        )}
      </Accordion>


    </>
  )
}

export default Statistics;